<script setup lang="ts">
import { h, unref } from 'vue';
import { toast } from '~/Components/ui/toast'

// form UI components
import { Input } from '~/Components/ui/input'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/Components/ui/form'
import { Card, CardContent, CardHeader, CardTitle } from '~/Components/ui/card'

import PageHeading from '~/Components/v8/PageHeading.vue';
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod';
import CardFooter from '~/Components/ui/card/CardFooter.vue';
import Button from '~/Components/ui/button/Button.vue';
import Combobox from '~/Components/v8/ComboBox.vue';
import { back, convertKeysToSnakeCase } from '~/lib/utils';
import { useModuleApiStore } from '~/states/moduleApi';
import { OrganizationsContactsShow } from '~/types/serializers';
import { map } from 'lodash';
import { organizationsContacts } from '~/api';
import { useForm } from 'vee-validate';

interface CurrencyCode {
  code: string,
  currency_code: string,
  flag: string,
  name: string
}

const props = defineProps<{
  contact: OrganizationsContactsShow,
  currency_codes: CurrencyCode[]
}>()
const moduleApi = useModuleApiStore()
moduleApi.setUpdate(organizationsContacts.update)
moduleApi.setShow(organizationsContacts.show)

const itemSchema = toTypedSchema(z.object(
  {
    name: z.string(),
    legalName: z.string(),
    // notes: z.string(),
    currencyCode: z.string(),
    // countryCode: z.string(),
    creditLimit: z.number(),
  }
))

const initialValues = unref(props.contact)

// "id": null,
//   "addressInfo": [],
//     "bankAccountInfo": { },
// "businessInfo": { },
// "contactPersonInfo": [],
//               "taxInfo": { }

const form = useForm({
  validationSchema: itemSchema,
  initialValues: initialValues
})

const formConfig = {
  validateOnBlur: false,
  validateOnChange: false
}

const onSubmit = form.handleSubmit((values: any) => {
  toast({
    title: 'You submitted the following values:',
    description: h('pre', { class: 'mt-2 w-[340px] rounded-md bg-slate-950 p-4' }, h('code', { class: 'text-white' }, JSON.stringify(values, null, 2))),
  })

  const transformedValues = convertKeysToSnakeCase(values) // BE accept snake_case while giving out value in camelCase.

  if (initialValues.id) {
    moduleApi.gotoUpdate({ id: initialValues.id, ...transformedValues }, initialValues.id)
  } else {
    moduleApi.gotoCreate({ contact: values })
  }
})

const comboBoxItems = map(props.currency_codes, (currency) => {
  return {
    value: currency.currency_code,
    label: `${currency.flag} ${currency.currency_code} ${currency.name}`
  }
})
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="add_contact" />
  </div>

  <div class="grid items-start flex-1 gap-4 px-8 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
    <div class="grid items-start gap-4 auto-rows-max md:gap-8 lg:col-span-2">
      <form @submit="onSubmit">
        <Card>
          <CardHeader>
            <CardTitle>{{ $t('contact_details') }}</CardTitle>
          </CardHeader>
          <CardContent class="space-y-5">
            <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <FormField v-slot="{ componentField }" name="name">
                <FormItem>
                  <FormLabel>{{ $t('name') }}</FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="Alexander" v-bind="componentField" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="legalName" v-bind="formConfig">
                <FormItem>
                  <FormLabel>{{ $t('legal_name') }}</FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="Michael Jordan" v-bind="componentField" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div>

            <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <FormField v-slot="{ componentField }" name="creditLimit" v-bind="formConfig">
                <FormItem>
                  <FormLabel>{{ $t('credit_limit') }}</FormLabel>
                  <FormControl>
                    <Input type="number" placeholder="950" v-bind="componentField" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>
              <FormField v-slot="{ componentField }" name="currencyCode">
                <FormItem>
                  <FormLabel>{{ $t('currency_code') }}</FormLabel>
                  <FormControl>
                    <Combobox v-bind="componentField" :listItems="comboBoxItems" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div>
          </CardContent>

          <CardFooter class="justify-end space-x-2">
            <Button type="button" @click="back" variant="outline">{{ $t('cancel') }}</Button>
            <Button type="submit">{{ $t('save_changes') }}</Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  </div>
</template>
