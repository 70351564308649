<script setup lang="ts">
import type { Row } from '@tanstack/vue-table'
import DotsHorizontalIcon from '~icons/radix-icons/dots-horizontal'
import { Button } from '~/Components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '~/Components/ui/dropdown-menu'
import { useModuleApiStore } from '~/states/moduleApi'

interface DataTableRowActionsProps {
  row: Row<any>
}
const { row } = defineProps<DataTableRowActionsProps>()
const handleClickEdit = (rowId: string) => useModuleApiStore().gotoEdit(rowId)
const handleClickDelete = (rowId: string) => useModuleApiStore().gotoDelete(rowId)
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button
        variant="ghost"
        class="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
      >
        <DotsHorizontalIcon class="w-4 h-4" />
        <span class="sr-only">Open menu</span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" class="w-[160px]">
      <DropdownMenuItem @click="() => handleClickEdit(row.original.id)">Edit</DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem @click="() => handleClickDelete(row.original.id)">
        Delete
        <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
