<script setup lang="ts">
import { MenuProps, MenuRecordRaw } from "./types";
import SidebarItem from '~/Components/Sidebar/SidebarItem.vue';
import { V8Icon } from "~/Components/icon";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '~/Components/ui/accordion'
import { computed } from "vue";
import { isArray } from "lodash";

interface Props extends MenuProps {
  menus: MenuRecordRaw[];
}

const props = withDefaults(defineProps<Props>(), {
  collapse: false,
  // theme: 'dark',
});

const accordionDefault = computed(() => props.defaultActive)
const accordionType = computed(() => (props?.defaultActive && isArray(props?.defaultActive))  ? 'multiple' : 'single')
const visibleMenus = computed(() => props.menus.filter((menu) => menu.show !== false))
</script>

<template>
  <Accordion :default-value="accordionDefault" :type="accordionType">
    <AccordionItem v-for="menu in visibleMenus" :value="menu.name" :key="menu.name" class="border-none">

      <AccordionTrigger class="justify-between px-3" v-if="menu.children">
        <div class="flex items-center gap-2">
          <V8Icon :icon="menu.icon" class="w-5 h-5" />
          <span>{{ menu.name }}</span>
        </div>
      </AccordionTrigger>

      <div class="space-y-1">
        <SidebarItem :text="menu.name" :href="menu.path" v-if="!menu.children" size="icon" class="px-3">
          <template #icon>
            <V8Icon :icon="menu.icon" class="w-5 h-5 mr-2" />
          </template>
        </SidebarItem>
      </div>

      <AccordionContent>
        <div class="space-y-1" v-for="item in menu.children" :key="item.path">
          <SidebarItem :text="item.name" :href="item.path" v-if="item.show !== false">
            <template #icon>
              <V8Icon :icon="item.icon" class="w-5 h-5 mr-2" />
            </template>
          </SidebarItem>
        </div>
      </AccordionContent>

    </AccordionItem>
  </Accordion>
</template>
