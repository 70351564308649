import { PathHelper } from "@js-from-routes/client";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { router } from "@inertiajs/vue3";

export const useModuleApiStore = defineStore('moduleApi', () => {
  const newPath = ref<PathHelper>();
  const createPath = ref<PathHelper>();
  const editPath = ref<PathHelper>();
  const updatePath = ref<PathHelper>();
  const destroyPath = ref<PathHelper>();
  const showPath = ref<PathHelper>();
  const activeRecord = ref();
  const currentEditRecord = ref();
  const responseProps = ref();

  interface IEditOptions {
    sideAction?: () => void;
    accept?: "json";
    expectedKey?: string;
  }

  let newOptions: IEditOptions | undefined;
  let editOptions: IEditOptions | undefined;

  // https://github.com/inertiajs/inertia-laravel/issues/103#issuecomment-1116846463
  // https://gist.github.com/mohitmamoria/91da6f30d9610b211248225c9e52bebe
  interface IHeaders {
    "X-Inertia": boolean;
    "X-Inertia-Version": string | null;
  }
  const headers: IHeaders = reactive({
    "X-Inertia": true,
    "X-Inertia-Version": null
  });

  function preRun() {
    activeRecord.value = null
    responseProps.value = null
    currentEditRecord.value = null
  }

  function setNew(newPathHelper: PathHelper | any, options?: IEditOptions) {
    newOptions = options;

    newPath.value = newPathHelper;
  }

  function setCreate(createPathHelper: PathHelper) {
    createPath.value = createPathHelper
  }

  function setEdit(editPathHelper: PathHelper, options?: IEditOptions) {
    editOptions = options;

    editPath.value = editPathHelper;
  }

  function setUpdate(updatePathHelper: PathHelper) {
    updatePath.value = updatePathHelper;
  }

  function setShow(showPathHelper: PathHelper) {
    showPath.value = showPathHelper;
  }

  function setDestroy(destroyPathHelper: PathHelper) {
    destroyPath.value = destroyPathHelper;
  }

  async function gotoNew() {
    if (!newPath.value) {
      console.log("newPath is not set");
      return;
    }

    preRun();

    if (typeof newPath.value.path !== "function") {
      return newPath.value.call(null);
    }

    if (!newOptions) {
      console.log("[InertiaJS] routing...");
      return router.get(newPath.value.path());
    }

    if (!newOptions.expectedKey) {
      console.log("missing expectedKey");
      return;
    }

    if (newOptions.accept === "json") {
      console.log("[JS_from_route:axios] routing...");
      const response = await newPath.value({ headers });
      currentEditRecord.value = response.props[newOptions.expectedKey];
      responseProps.value = response.props;
    }

    if (newOptions.sideAction) {
      newOptions.sideAction.call(null);
    }
  }

  function gotoCreate(form: any) {
    console.log('createPath.value', createPath.value)
    if (createPath.value) router.post(createPath.value.path(), form);
  }

  async function gotoEdit(id: string) {
    console.log('gotoEdit', id)
    if (!editPath.value) {
      console.log("editPath is not set");
      return;
    }

    console.log('id', id)

    if (!editOptions) {
      console.log('[InertiaJS] routing...')
      return router.get(editPath.value.path({ id: id }));
    }

    if (!editOptions.expectedKey) {
      console.log('missing expectedKey')
      return
    }

    if (editOptions.accept === "json") {
      console.log("[JS_from_route:axios] routing...");
      const response = await editPath.value({ id: id, headers });
      currentEditRecord.value = response.props[editOptions.expectedKey];
      responseProps.value = response.props
    }

    if (editOptions.sideAction) {
      editOptions.sideAction.call(null)
    }
  }

  function gotoUpdate(params: {}, id?: any) {
    if (!updatePath.value) {
      console.log("updatePath is not set")
      return;
    }

    let editId

    if (id)
      editId = id;
    else if (currentEditRecord.value)
      editId = currentEditRecord.value.id;
    else {
      console.log('id is not given and/or record to update was not set')
      return;
    }

    router.patch(updatePath.value.path({ id: editId }), params);
  }

  function gotoDelete(id: string) {
    if (destroyPath.value) {
      const destroyUrl = destroyPath.value.path({ id: id });
      router.delete(destroyUrl);
      activeRecord.value = null
    }
  }

  function gotoShow(id: number | string) {
    if (showPath.value) {
      return showPath.value({ id: id, headers });
    }
  }

  function setVersion(version: string | null) {
    preRun()
    headers["X-Inertia-Version"] = version;
  }

  return {
    activeRecord,
    currentEditRecord,
    responseProps,

    newPath,
    createPath,
    editPath,
    updatePath,
    showPath,
    destroyPath,

    setVersion,
    setNew,
    setCreate,
    setEdit,
    setUpdate,
    setShow,
    setDestroy,

    gotoNew,
    gotoCreate,
    gotoEdit,
    gotoUpdate,
    gotoShow,
    gotoDelete,
  };
})
