
<script setup lang="ts">
import { Button } from '~/Components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '~/Components/ui/card'
import { Input } from '~/Components/ui/input'
import { Label } from '~/Components/ui/label'
import { Checkbox } from '~/Components/ui/checkbox'
import { Middle } from '~/Components/Wrapper'

import { Head, useForm } from '@inertiajs/vue3'
import api from '~/api'

const form = useForm({
  user: {
    email: "",
    password: "",
    remember_me: false
  }
})
</script>

<template>
  <Head title="Login" />

  <Middle>
    <Card class="w-full max-w-sm">
      <form class="space-y-4 md:space-y-6" @submit.prevent="form.post(api.usersSessions.create.path())">
        <CardHeader>
          <CardTitle class="text-2xl">
            Sign in to your account
          </CardTitle>
          <CardDescription>
            Enter your email below to login to your account.
          </CardDescription>
        </CardHeader>
        <CardContent class="grid gap-4">
          <div class="grid gap-2">
            <Label for="email">Email</Label>
            <Input type="email" name="email" id="email" v-model="form.user.email" placeholder="m@example.com" required />
          </div>
          <div class="grid gap-2">
            <div class="flex items-center justify-between">
              <Label for="password">Password</Label>
              <a href="#" class="inline-block ml-auto text-sm underline">
                Forgot your password?
              </a>
            </div>
            <Input type="password" name="password" id="password" placeholder="••••••••" v-model="form.user.password" required />
          </div>
          <div class="flex items-center space-x-2">
            <Checkbox id="remember" v-model="form.user.remember_me" />
            <label
              for="terms"
              class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Remember me
            </label>
          </div>
        </CardContent>
        <CardFooter>
          <Button class="w-full" type="submit">
            Sign in
          </Button>
        </CardFooter>
      </form>
    </Card>
  </Middle>
</template>
