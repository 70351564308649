import type { Updater } from '@tanstack/vue-table'
import { type ClassValue, clsx } from 'clsx'
import { mapKeys, snakeCase } from 'lodash'
import { twMerge } from 'tailwind-merge'
import type { Ref } from 'vue'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
  ref.value
    = typeof updaterOrValue === 'function'
      ? updaterOrValue(ref.value)
      : updaterOrValue
}

export const back = () => window.history.back();

export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

export function convertKeysToSnakeCase(source: any) {
  return mapKeys(source, (_val, key) => snakeCase(key))
}
