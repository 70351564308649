<script setup lang="ts">
import { type Component, computed } from 'vue';

import { Icon } from '~/base/icons';
import { isFunction, isHttpUrl, isObject, isString } from '../shared/utils';

const props = defineProps<{
  fallback?: boolean;
  icon?: Component | Function | string;
}>();

const isRemoteIcon = computed(() => {
  return isString(props.icon) && isHttpUrl(props.icon);
});

const isComponent = computed(() => {
  const { icon } = props;
  return !isString(icon) && (isObject(icon) || isFunction(icon));
});
</script>

<template>
  <component :is="icon as Component" v-if="isComponent" v-bind="$attrs" />
  <img v-else-if="isRemoteIcon" :src="icon as string" v-bind="$attrs" />
  <Icon v-else-if="icon" :icon="icon as string" v-bind="$attrs" />
</template>
