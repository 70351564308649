<script setup lang="ts">
</script>

<template>
  <div class="relative w-full">
    <main>
      <slot></slot>
    </main>
  </div>
</template>
