<script setup lang="ts">
import { useForm, usePage } from '@inertiajs/vue3'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/themes/dark.css'
import { computed } from 'vue'
import api from '~/api'
import { UsersProfile } from '~/types/serializers'


const page = usePage()
const user = computed(() => (page.props as any).auth.user as UsersProfile)

const config = {
  defaultDate: Date.now(),
  dateFormat: 'Z',
  altInput: true
}

const profileForm = useForm({
  user: {
    first_name: user.value.firstName,
    last_name: user.value.lastName,
    email: user.value.email,
    phone_number: user.value.phoneNumber,
    date_of_birth: user.value.dateOfBirth
  }
})

function submit() {
  profileForm.put(api.usersProfiles.update.path({ id: user.value.id }), {
    onError: (err) => {
      console.error(err)
    }
  })
}
</script>

<template>
  <h3 class="mb-4 text-xl font-semibold dark:text-white">General Information</h3>
  <form action="#" @submit.prevent="submit">
    <div class="mb-4">
      <label for="" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
      <input type="text" name="address" id="first-name" v-model="profileForm.user.first_name"
        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        placeholder="John">
    </div>
    <div class="mb-4">
      <label for="" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
      <input type="text" name="address" id="last-name" v-model="profileForm.user.last_name"
        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        placeholder="Smith">
    </div>
    <div class="mb-4">
      <label for="" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
      <input type="text" name="address" id="email" v-model="profileForm.user.email"
        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        placeholder="example@company.com">
    </div>
    <div class="mb-4">
      <label for="date-of-birth" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date Of
        Birth</label>
      <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path
              d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z">
            </path>
            <path clip-rule="evenodd" fill-rule="evenodd"
              d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z">
            </path>
          </svg>
        </div>
        <flat-pickr v-model="profileForm.user.date_of_birth"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-50"
          placeholder="Choose date..." :config="config" />
        <!-- <input name="start" type="text" id="date-of-birth" ref="datePickerEl"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder="From"> -->
      </div>
    </div>
    <div class="mb-4">
      <label for="" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile Phone</label>
      <div class="flex">
        <button id="dropdown-currency-button" data-dropdown-toggle="dropdown-currency"
          class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
          type="button">
          +60 <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 4 4 4-4" />
          </svg>
        </button>
        <div id="dropdown-country-code"
          class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-36 dark:bg-gray-700">
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-currency-button">
            <li>
              <button type="button"
                class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem">
                <div class="inline-flex items-center">
                  +60
                </div>
              </button>
            </li>
          </ul>
        </div>
        <input type="text" name="address" id="phone-number" v-model="profileForm.user.phone_number"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-e-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder="17-123456789">
      </div>
    </div>
    <div class="col-span-6 sm:col-full">
      <button
        class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        type="submit">Update Info</button>
    </div>
  </form>
</template>
