import { createInertiaApp } from '@inertiajs/vue3'
import { createPinia } from 'pinia'
import { createApp, h } from 'vue'
import { i18n } from "./i18n";

import UserLayout from '~/pages/Layout/UserLayout.vue'
import AdminLayout from '~/pages/Layout/AdminLayout.vue'
import DefaultLayout from '~/pages/Layout/DefaultLayout.vue'
import type { DefineComponent } from 'vue'
import { Config } from '@js-from-routes/client';
import { AxiosConfig } from '@js-from-routes/axios';

createInertiaApp({
  // Set default page title
  // see https://inertia-rails.netlify.app/guide/title-and-meta
  //
  title: title => title ? `${title} - ERP` : 'ERP',

  // Disable progress bar
  //
  // see https://inertia-rails.netlify.app/guide/progress-indicators
  // progress: false,

  resolve: (name): DefineComponent => {
    const pages = import.meta.glob<DefineComponent>('../pages/**/*.vue', { eager: true })

    // To use a default layout, import the Layout component
    // and use the following lines.
    // see https://inertia-rails.netlify.app/guide/pages#default-layouts
    //
    //

    const page = pages[`../pages/${name}.vue`]
    // page.default.layout ||= (page) => createElement(Layout, null, page)
    // page.default.layout = page.default.layout || UserLayout

    if (name.startsWith('Users/') || name.startsWith('Organizations/') && !name.includes('Invitations')) {
      page.default.layout = UserLayout
    } else if (name.startsWith('Admins/')) {
      page.default.layout = AdminLayout
    } else {
      page.default.layout = DefaultLayout
    }
    // page.default.layout = name.startsWith('Public/') ? undefined :
    return page
  },

  setup({ el, App, props, plugin }) {
    const pinia = createPinia()

    const previousFetch = Config.fetch;
    Config.fetch = (...args) => {
      return previousFetch(...args);
    }

    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(pinia)
      .use(i18n)
      .mount(el)
  },
})
