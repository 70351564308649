<script setup lang="ts">
</script>

<template>
  <div class="col-span-full xl:col-span-2">
    <div
      class="col-span-full p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800  ">
      <slot></slot>
    </div>
  </div>
</template>
