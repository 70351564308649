import type { ColumnDef } from '@tanstack/vue-table'
import { h } from 'vue'
import { onlineStatuses, invitationStatuses } from '../data/data'
import type { Task } from '../data/schema'
import DataTableColumnHeader from './DataTableColumnHeader.vue'
import DataTableRowActions from './DataTableRowActions.vue'
import { Checkbox } from '~/Components/ui/checkbox'
import { Badge } from '~/Components/ui/badge'
import { t } from '~/entrypoints/i18n'

export const columns: ColumnDef<Task>[] = [
  {
    id: 'select',
    header: ({ table }) => h(Checkbox, {
      'checked': table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
      'onUpdate:checked': value => table.toggleAllPageRowsSelected(!!value),
      'ariaLabel': 'Select all',
      'class': 'translate-y-0.5',
    }),
    cell: ({ row }) => h(Checkbox, { 'checked': row.getIsSelected(), 'onUpdate:checked': value => row.toggleSelected(!!value), 'ariaLabel': 'Select row', 'class': 'translate-y-0.5' }),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('organization.name') }),
    cell: ({ row }) => {
      return h('div', { class: 'flex flex-col' }, [
        h('div', { class: 'truncate' }, row.getValue('name')),
        h('div', { class: 'text-xs' }, row.original.role)
      ])
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'email',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('organization.member.email') }),

    cell: ({ row }) => {
      const label = onlineStatuses.find(onlineStatus => onlineStatus.value === row.original.onlineStatus)
      const variant = label?.value === 'online' ? 'online' : 'outline'

      return h('div', { class: 'flex space-x-2' }, [
        label ? h(Badge, { variant: variant }, () => label.label) : null,
        h('span', { class: 'max-w-[500px] truncate font-medium' }, row.getValue('email')),
      ])
    },
  },
  {
    accessorKey: 'invitationStatus',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('status') }),

    cell: ({ row }) => {
      const status = invitationStatuses.find(
        invitationStatus => invitationStatus.value === row.getValue('invitationStatus'),
      )

      if (!status)
        return null

      return h('div', { class: 'flex w-[100px] items-center' }, [
        status.icon && h(status.icon, { class: 'mr-2 h-4 w-4 text-muted-foreground' }),
        h('span', status.label),
      ])
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => h(DataTableRowActions, { row }),
  },
]
