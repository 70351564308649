<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import PageHeading from '~/Components/v8/PageHeading.vue'
import { organizationsBankAccounts } from '~/api'
import { useModuleApiStore } from '~/states/moduleApi'
import type PagyMetadata from '~/types/Metadata'
import { IBankAccount } from '~/pages/Organizations/BankAccounts/components/schema'

defineProps<{ accounts: IBankAccount[], metadata: PagyMetadata }>()
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsBankAccounts.new)
moduleApi.setShow(organizationsBankAccounts.show)
moduleApi.setEdit(organizationsBankAccounts.edit)
moduleApi.setDestroy(organizationsBankAccounts.destroy)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="bank_accounts" />
    <DataTable :data="accounts" :columns="columns" />
  </div>
</template>
