<script setup lang="ts">

</script>

<template>
  <div class="bg-white dark:bg-gray-900 dark:text-white">
    <div class="max-w-2xl mx-auto">
      <slot></slot>
    </div>
  </div>
</template>
