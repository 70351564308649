// JsFromRoutes CacheKey 5f101013d9872e1e58486bf66f76d3fd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  deactivate: /* #__PURE__ */ definePathHelper('put', '/admins/organizations/:id/deactivate'),
  index: /* #__PURE__ */ definePathHelper('get', '/admins/organizations'),
  create: /* #__PURE__ */ definePathHelper('post', '/admins/organizations'),
  show: /* #__PURE__ */ definePathHelper('get', '/admins/organizations/:id'),
}
