// JsFromRoutes CacheKey 2cd11018e9a2811500a454975d6662bb
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/organization/members'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/members'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/members/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/members/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/members/:id'),
}
