<script setup lang="ts">
import { ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import PageHeading from '~/Components/v8/PageHeading.vue';
import DialogForm from '~/pages/Organizations/Taxes/components/DialogForm.vue';
import { useModuleApiStore } from '~/states/moduleApi';
import PagyMetadata from '~/types/Metadata';
import { OrganizationsTaxesIndex } from '~/types/serializers';
import { organizationsTaxes } from '~/api';

defineProps<{
  taxes: OrganizationsTaxesIndex[],
  metadata: PagyMetadata
}>()

const dialogOpen = ref(false)
const openDialog = () => dialogOpen.value = true
const moduleApi = useModuleApiStore()

moduleApi.setVersion(usePage().version)
moduleApi.setNew(openDialog)
moduleApi.setCreate(organizationsTaxes.create)
moduleApi.setUpdate(organizationsTaxes.update)
moduleApi.setEdit(organizationsTaxes.edit, { sideAction: openDialog, accept: 'json', expectedKey: 'tax' })
moduleApi.setShow(organizationsTaxes.show)
moduleApi.setDestroy(organizationsTaxes.destroy)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="tax_rates" />
    <DataTable :data="taxes" :columns="columns" />
  </div>
  <DialogForm :dialogOpen="dialogOpen" @toggle="(open) => dialogOpen = open" />
</template>
