<script setup lang="ts">
import type { Table } from '@tanstack/vue-table'
import { computed } from 'vue'
// import type { OrganizationsTaxesIndex } from '~/types/serializers'

import DataTableViewOptions from './DataTableViewOptions.vue'
import Cross2Icon from '~icons/radix-icons/cross-2'
import { Button } from '~/Components/ui/button'
import { Input } from '~/Components/ui/input'
import { V8Icon } from '~/Components/icon'
import { useModuleApiStore } from '~/states/moduleApi'

interface DataTableToolbarProps {
  filterColumn: string
  table: Table<any>
}

const props = defineProps<DataTableToolbarProps>()
const isFiltered = computed(() => props.table.getState().columnFilters.length > 0)
const moduleApi = useModuleApiStore()
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center flex-1 space-x-2">
      <Input :placeholder="`Filter ${filterColumn}...`"
        :model-value="(table.getColumn(filterColumn)?.getFilterValue() as string) ?? ''"
        class="h-8 w-[150px] lg:w-[250px]"
        @input="table.getColumn(filterColumn)?.setFilterValue($event.target.value)" />

      <Button v-if="isFiltered" variant="ghost" class="h-8 px-2 lg:px-3" @click="table.resetColumnFilters()">
        {{ $t('reset') }}
        <Cross2Icon class="w-4 h-4 ml-2" />
      </Button>
    </div>
    <div class="flex items-center gap-2 ml-auto">
      <DataTableViewOptions :table="table" />

      <Button variant="outline" size="sm" @click="() => moduleApi.gotoNew()"
        class="hidden h-8 gap-1 px-3 ml-auto text-green-700 border-green-300 hover:text-green-600 lg:flex">
        <V8Icon icon="ic:twotone-add" class="w-5 h-5" /> {{ $t('add') }}
      </Button>
    </div>
  </div>
</template>
