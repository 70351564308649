<script setup lang="ts">
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import PageHeading from '~/Components/v8/PageHeading.vue';
import { OrganizationsContactsIndex, OrganizationsContactsShow } from '~/types/serializers';
import PagyMetadata from '~/types/Metadata';
import DetailsCard from '~/pages/Organizations/Contacts/components/_DetailsCard.vue';
import { usePage } from '@inertiajs/vue3';
import { organizationsContacts } from '~/api'
import { useModuleApiStore } from '~/states/moduleApi';

defineProps<{ contacts: OrganizationsContactsIndex[], metadata: PagyMetadata }>()
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsContacts.new)
moduleApi.setCreate(organizationsContacts.create)
moduleApi.setEdit(organizationsContacts.edit)
moduleApi.setUpdate(organizationsContacts.update)
moduleApi.setShow(organizationsContacts.show)
moduleApi.setDestroy(organizationsContacts.destroy)

const clicked = async (row: OrganizationsContactsIndex) => {
  const response = await moduleApi.gotoShow(row.id)
  const { contact } = response.props
  moduleApi.activeRecord = contact as OrganizationsContactsShow
}
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="contacts" />
  </div>
  <div class="grid items-start flex-1 gap-4 px-8 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
    <div class="grid items-start gap-4 auto-rows-max md:gap-8 lg:col-span-2">
      <DataTable :data="contacts" :columns="columns" @activeRow="clicked" />
    </div>
    <div>
      <DetailsCard :contact="moduleApi.activeRecord" v-if="moduleApi.activeRecord" />
    </div>
  </div>
</template>
