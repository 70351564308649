<script setup lang="ts">
import { router } from '@inertiajs/vue3'
import { Button } from '~/Components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '~/Components/ui/card'
import { Input } from '~/Components/ui/input'
import { Label } from '~/Components/ui/label'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/Components/ui/form'

import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import api from '~/api'

const props = defineProps<{
  invitation_token: string
}>()

const signInSchema = toTypedSchema(
  z.object({
    user: z.object({
      invitation_token: z.string(),
      password: z.string(),
      password_confirmation: z.string()
    })
  })
)

const { handleSubmit } = useForm({
  validationSchema: signInSchema,
  initialValues: {
    user: {
      invitation_token: props.invitation_token
    }
  }
})

const onSubmit = handleSubmit((values, actions) => {
  console.log('value => ', values)

  router.put(api.usersInvitations.update.path(), values, {
    onError: (err: any) => {
      console.log('err', err)
    }
  })
})
</script>

<template>
  <div class="fixed z-50 grid -translate-x-1/2 -translate-y-1/2 border top-1/2 left-1/2">
    <form action="" @submit="onSubmit">
      <Card class="w-[350px]">
        <CardHeader class="space-y-1">
          <CardTitle class="text-2xl text-center">
            {{ $t('invitation.accept_welcome') }}
          </CardTitle>
          <CardDescription>
            {{ $t('invitation.accept_description') }}
          </CardDescription>
        </CardHeader>
        <CardContent class="grid gap-4">
          <FormField v-slot="{ componentField }" name="user.password">
            <FormItem>
              <FormLabel>{{ $t('password') }}</FormLabel>
              <FormControl><Input type="password" v-bind="componentField" /></FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="user.password_confirmation">
            <FormItem>
              <FormLabel>{{ $t('password_confirmation') }}</FormLabel>
              <FormControl><Input type="password" v-bind="componentField" /></FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
        </CardContent>
        <CardFooter>
          <Button class="w-full">
            {{ $t('form.button.create_account') }}
          </Button>
        </CardFooter>
      </Card>
    </form>
  </div>
</template>
