import { h } from 'vue'
import ArrowDownIcon from '~icons/radix-icons/arrow-down'
import ArrowRightIcon from '~icons/radix-icons/arrow-right'
import ArrowUpIcon from '~icons/radix-icons/arrow-up'
import CheckCircledIcon from '~icons/radix-icons/check-circled'
import CircleIcon from '~icons/radix-icons/circle'
import CrossCircledIcon from '~icons/radix-icons/cross-circled'
import QuestionMarkCircledIcon from '~icons/radix-icons/question-mark-circled'
import StopwatchIcon from '~icons/radix-icons/stopwatch'

export const onlineStatuses = [
  {
    value: 'online',
    label: 'Online',
  },
  {
    value: 'offline',
    label: 'Offline',
  }
]

export const invitationStatuses = [
  {
    value: 'pending',
    label: 'Pending',
    icon: h(CircleIcon),
  },
  {
    value: 'invited',
    label: 'Invited',
    icon: h(StopwatchIcon),
  },
  {
    value: 'accepted',
    label: 'Accepted',
    icon: h(CheckCircledIcon),
  },
  {
    value: 'rejected',
    label: 'Rejected',
    icon: h(CrossCircledIcon),
  },
]

export const priorities = [
  {
    value: 'low',
    label: 'Low',
    icon: h(ArrowDownIcon),
  },
  {
    value: 'medium',
    label: 'Medium',
    icon: h(ArrowRightIcon),
  },
  {
    value: 'high',
    label: 'High',
    icon: h(ArrowUpIcon),
  },
]
