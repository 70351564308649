<script setup lang="ts">
import { Button } from '~/Components/ui/button'
import _pick from 'lodash/pick'
import _snakeCase from 'lodash/snakeCase'
import {
  MoreVertical,
} from 'lucide-vue-next'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '~/Components/ui/card'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '~/Components/ui/dropdown-menu'
import {
  Pagination,
  PaginationList,
  PaginationNext,
  PaginationPrev,
} from '~/Components/ui/pagination'
import { Separator } from '~/Components/ui/separator'
import { V8Icon } from '~/Components/icon'
import { OrganizationsContactsShow } from '~/types/serializers'
import { t } from '~/entrypoints/i18n'
import { useModuleApiStore } from '~/states/moduleApi'

interface IProp {
  contact: OrganizationsContactsShow
}
defineProps<IProp>()
const handleClickEdit = (rowId: string | number) => useModuleApiStore().gotoEdit(rowId.toString())
</script>

<template>
  <Card class="overflow-hidden">
    <CardHeader class="flex flex-row items-start bg-muted/50">
      <div class="grid gap-0.5">
        <CardTitle class="flex items-center gap-2 text-lg group">
          {{ contact.name }}
        </CardTitle>
      </div>
      <div class="flex items-center gap-1 ml-auto">
        <Button size="sm" variant="outline" class="h-8 gap-1" @click="() => handleClickEdit(contact.id)">
          <V8Icon icon="ic:twotone-edit" class="w-5 h-5" />
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button size="icon" variant="outline" class="w-8 h-8">
              <MoreVertical class="h-3.5 w-3.5" />
              <span class="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Trash</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </CardHeader>
    <CardContent class="p-6 text-sm">
      <div class="grid gap-3">
        <ul class="grid gap-3">
          <li class="flex items-center justify-between" v-for="(val, key) in _pick(contact, [
            'legalName',
            'currencyCode',
            'creditLimit',
            'receivable',
            'payable'
            ])">
            <span class="text-muted-foreground">
              {{ t(_snakeCase(key)) }}
            </span>
            <span>{{ val }}</span>
          </li>
        </ul>
      </div>
      <Separator class="my-4" />
      <div class="grid gap-3">
        <div class="font-semibold">
          {{ t('bank_account_info') }}
        </div>
        <ul class="grid gap-3">
          <li class="flex items-center justify-between">
            <span class="text-muted-foreground">
              {{ contact.bankAccountInfo.accountName }}
            </span>
            <span>{{ contact.bankAccountInfo.accountNumber }}</span>
          </li>
        </ul>
      </div>
      <Separator class="my-4" />
      <div class="grid gap-3">
        <div class="font-semibold">
          {{ t('contact_person') }}
        </div>
        <ul class="grid gap-3" v-for="contact in contact.contactPersonInfo">
          <li class="flex items-center justify-between" v-for="(val, key) in contact">
            <span class="text-muted-foreground">
              {{ t(_snakeCase(key)) }}
            </span>
            <span>{{ val }}</span>
          </li>
        </ul>
      </div>
      <Separator class="my-4" />
      <div class="grid grid-cols-2 gap-4" v-for="addressInfo in contact.addressInfo">
        <div class="grid gap-3">
          <div class="font-semibold">
            {{ t('address') }}
          </div>
          <address class="grid gap-0.5 not-italic text-muted-foreground">
            <span>{{ addressInfo.streetAddress }}</span>
            <span>{{ addressInfo.city }}</span>
            <span>{{ addressInfo.postcode }} {{ addressInfo.state }}</span>
            <span>{{ addressInfo.country }}</span>
          </address>
        </div>
        <div class="grid gap-3">
          <div class="font-semibold"></div>
          <div class="text-muted-foreground" v-if="addressInfo.isDefaultShipping">
            Default<br>Shipping Address
          </div>
        </div>
      </div>
    </CardContent>
    <CardFooter class="flex flex-row items-center px-6 py-3 border-t bg-muted/50">
      <Pagination class="w-auto ml-auto mr-0">
        <PaginationList class="gap-1">
          <PaginationPrev variant="outline" class="w-6 h-6" />
          <PaginationNext variant="outline" class="w-6 h-6" />
        </PaginationList>
      </Pagination>
    </CardFooter>
  </Card>
</template>
