import type { ColumnDef } from '@tanstack/vue-table'
import { h } from 'vue'
import { intlFormat } from "date-fns";
import type { OrganizationsMembersIndex } from '~/types/serializers'
import DataTableColumnHeader from './DataTableColumnHeader.vue'
import DataTableRowActions from './DataTableRowActions.vue'
import { Checkbox } from '~/Components/ui/checkbox'
import { t } from '~/entrypoints/i18n';

export const columns: ColumnDef<OrganizationsMembersIndex>[] = [
  {
    id: 'select',
    header: ({ table }) => h(Checkbox, {
      'checked': table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
      'onUpdate:checked': value => table.toggleAllPageRowsSelected(!!value),
      'ariaLabel': 'Select all',
      'class': 'translate-y-0.5',
    }),
    cell: ({ row }) => h(Checkbox, { 'checked': row.getIsSelected(), 'onUpdate:checked': value => row.toggleSelected(!!value), 'ariaLabel': 'Select row', 'class': 'translate-y-0.5' }),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'firstName',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('organization.name') }),
    cell: ({ row }) => {
      return h('div', { class: 'flex flex-col' }, [
        h('div', { class: 'truncate' }, row.getValue('firstName')),
        h('div', { class: 'text-xs' }, "row.original.role")
      ])
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'email',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('organization.member.email') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'max-w-[500px] truncate font-medium' }, row.getValue('email')),
      ])
    },
  },
  {
    accessorKey: 'lastSeenAt',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('last_seen_at') }),

    cell: ({ row }) => {
      const lastSeenAt: string = row.getValue('lastSeenAt') ? intlFormat(row.getValue('lastSeenAt')) : t('never')

      return h('div', { class: 'flex w-[100px] items-center' }, [
        h('span', lastSeenAt),
      ])
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => h(DataTableRowActions, { row }),
  },
]
