import type { ColumnDef } from '@tanstack/vue-table'
import { h } from 'vue'
import { t } from '~/entrypoints/i18n'
import { Checkbox } from '~/Components/ui/checkbox'

import type { OrganizationsItemsIndex } from '~/types/serializers'
import DataTableColumnHeader from '~/Components/v8/DataTable/DataTableColumnHeader.vue'
import DataTableRowActions from '~/Components/v8/DataTable/DataTableRowActions.vue'
import _startCase from 'lodash/startCase'

export const columns: ColumnDef<OrganizationsItemsIndex>[] = [
  {
    id: 'select',
    header: ({ table }) => h(Checkbox, {
      'checked': table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
      'onUpdate:checked': value => table.toggleAllPageRowsSelected(!!value),
      'ariaLabel': 'Select all',
      'class': 'translate-y-0.5',
    }),
    cell: ({ row }) => h(Checkbox, { 'checked': row.getIsSelected(), 'onUpdate:checked': value => row.toggleSelected(!!value), 'ariaLabel': 'Select row', 'class': 'translate-y-0.5' }),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'code',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('code').toUpperCase() }),
    cell: ({ row }) => h('div', { class: 'w-30' }, row.original.code)
  },
  {
    accessorKey: 'name',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('name') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'max-w-[500px] truncate font-medium' }, row.getValue('name')),
      ])
    },
  },
  {
    accessorKey: 'quantity',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('quantity') }),

    cell: ({ row }) => {
      return h('div', { class: 'w-30' }, [
        h('span', { class: 'font-medium' }, row.getValue('quantity')),
      ])
    },
  },
  {
    accessorKey: 'price',
    header: ({ column }) => h(DataTableColumnHeader, {
      column,
      title: _startCase(t('price')),
      class: 'w-30'
    }),

    cell: ({ row }) => {
      return h('div', { class: 'w-30' }, [
        h('span', { class: 'font-medium' }, row.getValue('price')),
      ])
    },
  },
  {
    accessorKey: 'cost',
    header: ({ column }) => h(DataTableColumnHeader, {
      column,
      title: _startCase(t('cost')),
      class: 'w-30'
    }),

    cell: ({ row }) => {
      return h('div', { class: 'w-30' }, [
        h('span', { class: 'font-medium' }, row.getValue('cost')),
      ])
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => h(DataTableRowActions, { row }),
  },
]
