<script setup lang="ts">
import tasks from './data/tasks.json'
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <div class="flex items-center justify-between space-y-2">
      <div>
        <h2 class="text-2xl font-bold tracking-tight">
          {{ $t('members') }}
        </h2>
        <p class="text-muted-foreground">
          {{ $t('members_description') }}
        </p>
      </div>
    </div>
    <DataTable :data="tasks" :columns="columns" />
  </div>
</template>
