<script setup lang="ts"></script>

<template>
  <div class="grid items-start flex-1 gap-4 px-8 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
    <div class="grid items-start gap-4 auto-rows-max md:gap-8 lg:col-span-2">
      <slot></slot>
    </div>
    <div>
      <slot name="last"></slot>
    </div>
  </div>
</template>
