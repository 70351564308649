<script setup lang="ts">
import type { Table } from '@tanstack/vue-table'
import { computed } from 'vue'
// import type { OrganizationsTaxesIndex } from '~/types/serializers'
import MixerHorizontalIcon from '~icons/radix-icons/mixer-horizontal'
import { Button } from '~/Components/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/Components/ui/dropdown-menu'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({
  messages: {
    en: {
      totalPercentage: "Total Percentage",
      accountType: "Account Type",
      ytd: "Year-To-Date",
      toggle_column: "Toggle columns"
    }
  }
})

interface DataTableViewOptionsProps {
  table: Table<any>
}

const props = defineProps<DataTableViewOptionsProps>()

const columns = computed(() => props.table.getAllColumns()
  .filter(
    column =>
      typeof column.accessorFn !== 'undefined' && column.getCanHide(),
  ))
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button
        variant="outline"
        size="sm"
        class="hidden h-8 ml-auto lg:flex"
      >
        <MixerHorizontalIcon class="w-4 h-4 mr-2" />
        {{ t('view') }}
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" class="w-[150px]">
      <DropdownMenuLabel>{{ t('toggle_column') }}</DropdownMenuLabel>
      <DropdownMenuSeparator />

      <DropdownMenuCheckboxItem
        v-for="column in columns"
        :key="column.id"
        class="capitalize"
        :checked="column.getIsVisible()"
        @update:checked="(value) => column.toggleVisibility(!!value)"
      >
        {{ t(`${column.id}`) }}
      </DropdownMenuCheckboxItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
