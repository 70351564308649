// JsFromRoutes CacheKey 4f2f806b6d9c4967c5432ac2f0b567fd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admins/admins'),
  create: /* #__PURE__ */ definePathHelper('post', '/admins/admins'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admins/admins/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admins/admins/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admins/admins/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admins/admins/:id'),
}
