import type { ColumnDef } from '@tanstack/vue-table'
import { h } from 'vue'

import DataTableColumnHeader from '~/Components/v8/DataTable/DataTableColumnHeader.vue'
import DataTableRowActions from '~/Components/v8/DataTable/DataTableRowActions.vue'
import { Checkbox } from '~/Components/ui/checkbox'
import { IBankAccount } from '~/pages/Organizations/BankAccounts/components/schema'

export const columns: ColumnDef<IBankAccount>[] = [
  {
    id: "select",
    header: ({ table }) =>
      h(Checkbox, {
        checked:
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate"),
        "onUpdate:checked": (value) => table.toggleAllPageRowsSelected(!!value),
        ariaLabel: "Select all",
        class: "translate-y-0.5",
      }),
    cell: ({ row }) =>
      h(Checkbox, {
        checked: row.getIsSelected(),
        "onUpdate:checked": (value) => row.toggleSelected(!!value),
        ariaLabel: "Select row",
        class: "translate-y-0.5",
      }),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "code",
    header: ({ column }) => h(DataTableColumnHeader, { column, title: "Code" }),
    cell: ({ row }) => h("div", { class: "w-20" }, row.getValue("code")),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: ({ column }) => h(DataTableColumnHeader, { column, title: "Name" }),

    cell: ({ row }) => {
      return h("div", { class: "flex space-x-2" }, [
        h(
          "span",
          { class: "max-w-[500px] truncate font-medium" },
          row.getValue("name")
        ),
      ]);
    },
  },

  {
    accessorKey: "bankName",
    header: ({ column }) =>
      h(DataTableColumnHeader, { column, title: "Bank Name" }),

    cell: ({ row }) => {
      return h("div", { class: "flex space-x-2" }, [
        h(
          "span",
          { class: "max-w-[500px] truncate font-medium" },
          row.getValue("bankName")
        ),
      ]);
    },
  },

  {
    accessorKey: "accountNumber",
    header: ({ column }) =>
      h(DataTableColumnHeader, { column, title: "Account Number" }),

    cell: ({ row }) => {
      return h(
        "div",
        { class: "flex w-[100px] items-center" },
        h("span", row.original.accountNumber)
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },

  {
    accessorKey: "type",
    header: ({ column }) => h(DataTableColumnHeader, { column, title: "Type" }),

    cell: ({ row }) => {
      return h("div", { class: "flex space-x-2" }, [
        h(
          "span",
          { class: "max-w-[500px] truncate font-medium" },
          row.getValue("type")
        ),
      ]);
    },
  },

  {
    id: "actions",
    cell: ({ row }) => h(DataTableRowActions, { row }),
  },
];
