<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useI18n } from 'vue-i18n';
import { z } from 'zod';
import { organizationsItems } from '~/api';
import Button from '~/Components/ui/button/Button.vue';
import H5 from '~/Components/v8/Typography/H5.vue';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from '~/Components/ui/card';
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage
} from '~/Components/ui/form';
import Input from '~/Components/ui/input/Input.vue';
import Separator from '~/Components/ui/separator/Separator.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import TwoThirdWrap from '~/Components/Wrapper/TwoThirdWrap.vue';
import { useModuleApiStore } from '~/states/moduleApi';
import { FieldArray } from 'vee-validate';
import { V8Icon } from '~/Components/icon';
import { back } from '~/lib/utils';

// sample component localization
const { t } = useI18n()

const itemSchema = toTypedSchema(z.object(
  {
    name: z.string(),
    code: z.string(),
    uoms: z.array(z.object({
      name: z.string(),
      quantity: z.number(),
      base: z.boolean().optional(),
      cost: z.number().optional(),
      price: z.number().optional()
    })),
    purchase_description: z.string(),
    sales_description: z.string()
  }
))

const initialValues = {
  uoms: [{
    name: '',
    quantity: 0
  }]
}

const moduleApi = useModuleApiStore();
moduleApi.setCreate(organizationsItems.create)

const onSubmit = (values: any) => {
  console.log('onSubmit', values)

  moduleApi.gotoCreate({
    item: values
  })
}

</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="add_item" />
  </div>

  <TwoThirdWrap>
    <Form :validation-schema="itemSchema" :initial-values="initialValues" as="form" @submit="onSubmit">
      <Card>
        <CardHeader>
          <CardTitle>{{ t('new_item') }}</CardTitle>
        </CardHeader>

        <CardContent class="grid gap-6">

          <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <FormField v-slot="{ componentField }" name="name">
              <FormItem>
                <FormLabel>{{ $t('name') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="Pencil Staedler" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="code">
              <FormItem>
                <FormLabel>{{ $t('code') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="ISBN-12345" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>

          <FormField v-slot="{ componentField }" name="purchase_description">
            <FormItem>
              <FormLabel>{{ $t('purchase_description') }}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="" v-bind="componentField" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>

          <FormField v-slot="{ componentField }" name="sales_description">
            <FormItem>
              <FormLabel>{{ $t('sales_description') }}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="" v-bind="componentField" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>

          <Separator />

          <H5>{{ t('unit_of_measurements') }}</H5>

          <div>
            <div class="flex justify-between gap-10 py-1 mb-2 bg-blue-100 items-top">
              <div class="w-1/4 px-2 text-sm text-cyan-900">Label</div>
              <div class="w-1/4 px-2 text-sm text-cyan-900">Quantity</div>
              <div class="w-1/4 px-2 text-sm text-cyan-900">Cost</div>
              <div class="w-1/4 px-2 text-sm text-cyan-900">Price</div>
            </div>
            <FieldArray v-slot="{ fields, push, remove }" name="uoms">
              <div v-for="(field, index) in fields" :key="`items-${field.key}`">
                <div class="flex justify-between pb-5 items-top">
                  <FormField v-slot="{ componentField }" :name="`uoms[${index}].name`">
                    <FormItem>
                      <FormControl>
                        <Input type="text" v-bind="componentField" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </FormField>

                  <FormField v-slot="{ componentField }" :name="`uoms[${index}].quantity`">
                    <FormItem>
                      <FormControl>
                        <Input type="number" v-bind="componentField" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </FormField>

                  <FormField v-slot="{ componentField }" :name="`uoms[${index}].price`">
                    <FormItem>
                      <FormControl>
                        <Input type="number" v-bind="componentField" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </FormField>

                  <FormField v-slot="{ componentField }" :name="`uoms[${index}].cost`">
                    <FormItem class="relative pr-10">
                      <FormControl>
                        <Input type="number" v-bind="componentField" />
                      </FormControl>
                      <FormMessage />

                      <button type="button" class="absolute top-0 py-1 pl-2 pe-3 end-0 text-muted-foreground"
                        @click="remove(index)">
                        <V8Icon icon="lucide:x" class="w-4 h-4" />
                      </button>
                    </FormItem>
                  </FormField>
                </div>
              </div>
              <Button type="button" variant="outline" size="sm" class="w-20 mt-2 text-xs" @click="push({ value: '' })">
                {{ $t('add_uom') }}
              </Button>
            </FieldArray>
          </div>

        </CardContent>

        <CardFooter class="justify-end space-x-2">
          <Button type="button" @click="back" variant="outline">{{ $t('cancel') }}</Button>
          <Button type="submit">{{ $t('save_changes') }}</Button>
        </CardFooter>
      </Card>
    </Form>
  </TwoThirdWrap>
</template>


<!--
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '~/Components/ui/select';

<div class="grid gap-4 sm:grid-cols-2">
  <div class="grid gap-2">
    <Label :for="`area-${id}`">Area</Label>
    <Select defaultValue="billing">
      <SelectTrigger :id="`area-${id}`" aria-label="Area">
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="team">Team</SelectItem>
        <SelectItem value="billing">Billing</SelectItem>
        <SelectItem value="account">Account</SelectItem>
        <SelectItem value="deployments">Deployments</SelectItem>
        <SelectItem value="support">Support</SelectItem>
      </SelectContent>
    </Select>
  </div>
</div>
-->

<i18n lang="yaml">
en:
  new_item: 'New Item'
</i18n>
