// JsFromRoutes CacheKey 27fb5a273621475a7feb7f334b5bc123
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization'),
}
