<script setup lang="ts">
import { Link, usePage } from '@inertiajs/vue3'
import { computed } from 'vue';
import { ButtonVariants, buttonVariants } from '~/Components/ui/button';
import { cn } from '~/lib/utils';

const props = defineProps<{
  text: string,
  href: string,
  size?: ButtonVariants["size"]
}>()

const buttonVar = computed(() => props.href.endsWith(usePage().url) ? 'secondary' : 'ghost')
</script>

<template>
  <Link
    :href="href"
    as="button"
    :class="cn(
        [
          buttonVariants({
            variant: buttonVar,
            size: size
          }),
          'justify-start w-full flex rounded-none'
        ]
      )"
  >
    <slot name="icon"></slot>
    {{ text }}
    <slot name="badge"></slot>
  </Link>
</template>
