export default [
  ["united arab emirates dirham", "AED"],
  ["afghan afghani", "AFN"],
  ["albanian lek", "ALL"],
  ["armenian dram", "AMD"],
  ["netherlands antillean guilder", "ANG"],
  ["angolan kwanza", "AOA"],
  ["argentine peso", "ARS"],
  ["australian dollar", "AUD"],
  ["aruban florin", "AWG"],
  ["azerbaijani manat", "AZN"],
  ["bosnia-herzegovina convertible mark", "BAM"],
  ["barbadian dollar", "BBD"],
  ["bangladeshi taka", "BDT"],
  ["bulgarian lev", "BGN"],
  ["bahraini dinar", "BHD"],
  ["burundian franc", "BIF"],
  ["bermudan dollar", "BMD"],
  ["brunei dollar", "BND"],
  ["bolivian boliviano", "BOB"],
  ["brazilian real", "BRL"],
  ["bahamian dollar", "BSD"],
  ["bitcoin", "BTC"],
  ["bhutanese ngultrum", "BTN"],
  ["botswanan pula", "BWP"],
  ["new belarusian ruble", "BYN"],
  ["belarusian ruble", "BYR"],
  ["belize dollar", "BZD"],
  ["canadian dollar", "CAD"],
  ["congolese franc", "CDF"],
  ["swiss franc", "CHF"],
  ["chilean unit of account (uf)", "CLF"],
  ["chilean peso", "CLP"],
  ["chinese yuan", "CNY"],
  ["colombian peso", "COP"],
  ["costa rican colón", "CRC"],
  ["cuban convertible peso", "CUC"],
  ["cuban peso", "CUP"],
  ["cape verdean escudo", "CVE"],
  ["czech republic koruna", "CZK"],
  ["djiboutian franc", "DJF"],
  ["danish krone", "DKK"],
  ["dominican peso", "DOP"],
  ["algerian dinar", "DZD"],
  ["egyptian pound", "EGP"],
  ["eritrean nakfa", "ERN"],
  ["ethiopian birr", "ETB"],
  ["euro", "EUR"],
  ["fijian dollar", "FJD"],
  ["falkland islands pound", "FKP"],
  ["british pound sterling", "GBP"],
  ["georgian lari", "GEL"],
  ["guernsey pound", "GGP"],
  ["ghanaian cedi", "GHS"],
  ["gibraltar pound", "GIP"],
  ["gambian dalasi", "GMD"],
  ["guinean franc", "GNF"],
  ["guatemalan quetzal", "GTQ"],
  ["guyanaese dollar", "GYD"],
  ["hong kong dollar", "HKD"],
  ["honduran lempira", "HNL"],
  ["croatian kuna", "HRK"],
  ["haitian gourde", "HTG"],
  ["hungarian forint", "HUF"],
  ["indonesian rupiah", "IDR"],
  ["israeli new sheqel", "ILS"],
  ["manx pound", "IMP"],
  ["indian rupee", "INR"],
  ["iraqi dinar", "IQD"],
  ["iranian rial", "IRR"],
  ["icelandic króna", "ISK"],
  ["jersey pound", "JEP"],
  ["jamaican dollar", "JMD"],
  ["jordanian dinar", "JOD"],
  ["japanese yen", "JPY"],
  ["kenyan shilling", "KES"],
  ["kyrgystani som", "KGS"],
  ["cambodian riel", "KHR"],
  ["comorian franc", "KMF"],
  ["north korean won", "KPW"],
  ["south korean won", "KRW"],
  ["kuwaiti dinar", "KWD"],
  ["cayman islands dollar", "KYD"],
  ["kazakhstani tenge", "KZT"],
  ["laotian kip", "LAK"],
  ["lebanese pound", "LBP"],
  ["sri lankan rupee", "LKR"],
  ["liberian dollar", "LRD"],
  ["lesotho loti", "LSL"],
  ["lithuanian litas", "LTL"],
  ["latvian lats", "LVL"],
  ["libyan dinar", "LYD"],
  ["moroccan dirham", "MAD"],
  ["moldovan leu", "MDL"],
  ["malagasy ariary", "MGA"],
  ["macedonian denar", "MKD"],
  ["myanma kyat", "MMK"],
  ["mongolian tugrik", "MNT"],
  ["macanese pataca", "MOP"],
  ["mauritanian ouguiya", "MRO"],
  ["mauritian rupee", "MUR"],
  ["maldivian rufiyaa", "MVR"],
  ["malawian kwacha", "MWK"],
  ["mexican peso", "MXN"],
  ["malaysian ringgit", "MYR"],
  ["mozambican metical", "MZN"],
  ["namibian dollar", "NAD"],
  ["nigerian naira", "NGN"],
  ["nicaraguan córdoba", "NIO"],
  ["norwegian krone", "NOK"],
  ["nepalese rupee", "NPR"],
  ["new zealand dollar", "NZD"],
  ["omani rial", "OMR"],
  ["panamanian balboa", "PAB"],
  ["peruvian nuevo sol", "PEN"],
  ["papua new guinean kina", "PGK"],
  ["philippine peso", "PHP"],
  ["pakistani rupee", "PKR"],
  ["polish zloty", "PLN"],
  ["paraguayan guarani", "PYG"],
  ["qatari rial", "QAR"],
  ["romanian leu", "RON"],
  ["serbian dinar", "RSD"],
  ["russian ruble", "RUB"],
  ["rwandan franc", "RWF"],
  ["saudi riyal", "SAR"],
  ["solomon islands dollar", "SBD"],
  ["seychellois rupee", "SCR"],
  ["sudanese pound", "SDG"],
  ["swedish krona", "SEK"],
  ["singapore dollar", "SGD"],
  ["saint helena pound", "SHP"],
  ["sierra leonean leone", "SLL"],
  ["somali shilling", "SOS"],
  ["surinamese dollar", "SRD"],
  ["são tomé and príncipe dobra", "STD"],
  ["salvadoran colón", "SVC"],
  ["syrian pound", "SYP"],
  ["swazi lilangeni", "SZL"],
  ["thai baht", "THB"],
  ["tajikistani somoni", "TJS"],
  ["turkmenistani manat", "TMT"],
  ["tunisian dinar", "TND"],
  ["tongan paʻanga", "TOP"],
  ["turkish lira", "TRY"],
  ["trinidad and tobago dollar", "TTD"],
  ["new taiwan dollar", "TWD"],
  ["tanzanian shilling", "TZS"],
  ["ukrainian hryvnia", "UAH"],
  ["ugandan shilling", "UGX"],
  ["united states dollar", "USD"],
  ["uruguayan peso", "UYU"],
  ["uzbekistan som", "UZS"],
  ["venezuelan bolívar fuerte", "VEF"],
  ["vietnamese dong", "VND"],
  ["vanuatu vatu", "VUV"],
  ["samoan tala", "WST"],
  ["cfa franc beac", "XAF"],
  ["silver (troy ounce)", "XAG"],
  ["gold (troy ounce)", "XAU"],
  ["east caribbean dollar", "XCD"],
  ["special drawing rights", "XDR"],
  ["cfa franc bceao", "XOF"],
  ["cfp franc", "XPF"],
  ["yemeni rial", "YER"],
  ["south african rand", "ZAR"],
  ["zambian kwacha (pre-2013)", "ZMK"],
  ["zambian kwacha", "ZMW"],
  ["zimbabwean dollar", "ZWL"]
]
