// JsFromRoutes CacheKey c14a6cb99e5f45ff76521da0939a12b6
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  archive: /* #__PURE__ */ definePathHelper('patch', '/organization/contacts/:id/archive'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/contacts'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/contacts'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/contacts/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/contacts/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/contacts/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/contacts/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/contacts/:id'),
}
