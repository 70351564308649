// JsFromRoutes CacheKey bf1be1a3297c73b27a2ea5b2036831cd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/organization/taxes'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/taxes'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/taxes/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/taxes/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/taxes/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/taxes/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/taxes/:id'),
}
