<script setup lang="ts">
import { OrganizationsInvoicesIndex } from '~/types/serializers';
import DataTable from './components/DataTable.vue'
import { columns } from './components/columns'
import PageHeading from '~/Components/v8/PageHeading.vue';
import PagyMetadata from '~/types/Metadata';
import { organizationsInvoices } from '~/api';
import { usePage } from '@inertiajs/vue3';
import { useModuleApiStore } from '~/states/moduleApi';

defineProps<{ invoices: OrganizationsInvoicesIndex[], metadata: PagyMetadata }>()
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsInvoices.new)
moduleApi.setCreate(organizationsInvoices.create)
moduleApi.setShow(organizationsInvoices.show)
moduleApi.setDestroy(organizationsInvoices.destroy)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="invoices" />
    <DataTable :data="invoices" :columns="columns" />
  </div>
</template>
