import type { ColumnDef } from '@tanstack/vue-table'
import { h } from 'vue'
import { t } from '~/entrypoints/i18n'
import { Checkbox } from '~/Components/ui/checkbox'

import type { MySchema } from '../data/schema'
import DataTableColumnHeader from '~/Components/v8/DataTable/DataTableColumnHeader.vue'
import DataTableRowActions from '~/Components/v8/DataTable/DataTableRowActions.vue'

export const columns: ColumnDef<MySchema>[] = [
  {
    id: 'select',
    header: ({ table }) => h(Checkbox, {
      'checked': table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
      'onUpdate:checked': value => table.toggleAllPageRowsSelected(!!value),
      'ariaLabel': 'Select all',
      'class': 'translate-y-0.5',
    }),
    cell: ({ row }) => h(Checkbox, { 'checked': row.getIsSelected(), 'onUpdate:checked': value => row.toggleSelected(!!value), 'ariaLabel': 'Select row', 'class': 'translate-y-0.5' }),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'code',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('code') }),
    cell: ({ row }) => h('div', { class: 'w-20' }, row.original.code),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('name') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'max-w-[500px] truncate font-medium' }, row.getValue('name')),
      ])
    },
  },
  {
    accessorKey: 'accountType',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('account_type') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'font-medium' }, row.getValue('accountType')),
      ])
    },
  },
  {
    accessorKey: 'ytd',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: t('ytd') }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'font-medium' }, row.getValue('ytd')),
      ])
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => h(DataTableRowActions, { row }),
  },
]
