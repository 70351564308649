// JsFromRoutes CacheKey 378eaa189e1b002d371f4d4c320a2463
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  archive: /* #__PURE__ */ definePathHelper('patch', '/organization/accounts/:id/archive'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/accounts'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/accounts'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/accounts/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/accounts/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/accounts/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/accounts/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/accounts/:id'),
}
