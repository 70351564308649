<script setup lang="ts">
import { cn } from '~/lib/utils'
import { Button } from '~/Components/ui/button'
import { usePage, router } from '@inertiajs/vue3';
import { t } from '~/entrypoints/i18n';

interface Item {
  title: string
  href: string
}

const onClick = (navItem: Item) => router.get(navItem.href)

const sidebarNavItems: Item[] = [
  {
    title: t('organization.information'),
    href: '/my-org-a1/settings',
  },
  {
    title: 'Plan',
    href: '/my-org-a1/settings/plan',
  }
]
</script>
<!-- 'bg-muted hover:bg-muted', -->
<template>
  <nav class="flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1">
    <Button
      v-for="item in sidebarNavItems"
      :key="item.title"
      as="a"
      :href="item.href"
      @click.prevent="onClick(item)"
      variant="ghost"
      :class="cn(
        'w-full text-left justify-start',
        item.href === usePage().url && 'bg-muted hover:bg-muted'
      )"
    >
      {{ item.title }}
    </Button>
  </nav>
</template>
