<script setup lang="ts">
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'
import { Link } from '@inertiajs/vue3'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '~/Components/ui/avatar'
import { Button } from '~/Components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '~/Components/ui/dropdown-menu'
import { UsersProfile } from '~/types/serializers'
import api from '~/api'

const page = usePage()
const user = computed(() => (page.props.auth as any).user as UsersProfile)

const profileURL = computed(() => user.value.isAdmin ? api.adminsHomes.show.path() : api.usersProfiles.show.path())

// const props = defineProps<{ user: { firstName: string, lastName: string, email: string } }>()
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" class="relative w-8 h-8 rounded-full">
        <Avatar class="w-8 h-8">
          <AvatarImage src="/avatars/01.png" alt="@shadcn" />
          <AvatarFallback>SC</AvatarFallback>
          <span class="sr-only">Toggle user menu</span>
        </Avatar>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent class="w-56" align="end">

      <DropdownMenuLabel class="flex font-normal">
        <Link :href="profileURL" class="w-full h-full text-left" role="menuitem" as="button">
          My Profile
          <div class="flex flex-col space-y-1">
            <p class="text-sm font-medium leading-none">
              {{ user.isAdmin ? 'Admin' : 'ERP App' }}
            </p>
            <p class="text-xs leading-none text-muted-foreground">
              {{ user.email }}
            </p>
          </div>
        </Link>
      </DropdownMenuLabel>

      <DropdownMenuSeparator />
      <DropdownMenuItem>Account</DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem>
        <Link href="/users/logout" class="w-full h-full text-left" role="menuitem" method="delete" as="button">
          Logout
        </Link>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
