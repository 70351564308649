<script setup lang="ts">
import { FwbAvatar } from 'flowbite-vue'
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import type { AdminsUserIndex } from '~/types/serializers'
import UpdateUserModal from './UpdateUserModal.vue'
import api from '~/api'

defineProps<{ user: AdminsUserIndex }>()

const isShowEditModal = ref(false)

function showEditModal() {
  isShowEditModal.value = true
}

function closeEditModal() {
  isShowEditModal.value = false
}
</script>

<template>
  <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
    <!-- <td class="w-4 p-4">
                  <div class="flex items-center">
                    <input id="checkbox-{{ .id }}" aria-describedby="checkbox-1" type="checkbox"
                      class="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600">
                    <label for="checkbox-{{ .id }}" class="sr-only">checkbox</label>
                  </div>
                </td> -->
    <td class="flex items-center p-4 mr-12 space-x-6 whitespace-nowrap">
      <FwbAvatar initials="JD" rounded />
      <!-- <img class="w-10 h-10 rounded-full" src="/images/users/{{ .avatar }}" alt="{{ '.name' }} avatar"> -->
      <div class="text-sm font-normal text-gray-500 dark:text-gray-400">
        <div class="text-base font-semibold text-gray-900 dark:text-white">{{ `${user.firstName} ${user.lastName}` }}
        </div>
        <!-- <div class="text-sm font-normal text-gray-500 dark:text-gray-400">{{ user.email }}</div> -->
      </div>
    </td>
    <td
      class="max-w-sm p-4 overflow-hidden text-base font-normal text-gray-500 truncate xl:max-w-xs dark:text-gray-400">
      {{ user.email }}</td>
    <!-- <td class="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">{{ '.position' }}
                </td> -->
    <td class="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">{{ user.status }}
    </td>
    <td class="p-4 text-base font-normal text-gray-900 whitespace-nowrap dark:text-white">
      <div class="flex items-center">
        <div v-if="user.online" class="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div>
        <div v-if="!user.online" class="h-2.5 w-2.5 rounded-full bg-slate-500 mr-2"></div>
        {{ user.online ? 'Online' : 'Offline' }}
        <!-- {{ if eq .status "Active" }} <div class="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> {{ else }} <div class="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div> {{ end }} {{ .status }} -->
      </div>
    </td>
    <td class="p-4 space-x-2 whitespace-nowrap">
      <!-- <button type="button" data-modal-target="edit-user-modal" data-modal-toggle="edit-user-modal"
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 border border-primary-700 "></button> -->
      <button type="button" data-modal-target="edit-user-modal" data-modal-toggle="edit-user-modal"
        class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-primary-700 rounded-lg bg-white dark:bg-gray-800 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:hover:bg-primary-700 dark:focus:ring-primary-800 border border-primary-600 dark:hover:text-white">
        <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M2.038 5.61A2.01 2.01 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6c0-.12-.01-.238-.03-.352l-.866.65-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z" />
          <path
            d="M20.677 4.117A1.996 1.996 0 0 0 20 4H4c-.225 0-.44.037-.642.105l.758.607L12 10.742 19.9 4.7l.777-.583Z" />
        </svg>
        Send Invite
      </button>
      <button @click="showEditModal" type="button" data-modal-target="edit-user-modal"
        data-modal-toggle="edit-user-modal"
        class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-white dark:bg-gray-800 border border-gray-200 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
        <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
          <path fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"></path>
        </svg>
        Edit
      </button>
      <Link v-if="!user.blockedAt" :href="api.adminsUsers.block.path({ id: user.id })" as="button" method="put" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-orange-500 bg-white  dark:bg-gray-800 rounded-lg dark:text-orange-400 hover:bg-orange-600 hover:text-white focus:ring-4 focus:ring-red-300 dark:focus:ring-orange-900 border border-orange-400">
        <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
            d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        Block
      </Link>
      <Link v-if="user.blockedAt" :href="api.adminsUsers.unblock.path({ id: user.id })" as="button" method="put" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-orange-500 bg-white  dark:bg-gray-800 rounded-lg dark:text-orange-400 hover:bg-orange-600 hover:text-white focus:ring-4 focus:ring-red-300 dark:focus:ring-orange-900 border border-orange-400">
        <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
            d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        Unblock
      </Link>
      <button type="button" data-modal-target="delete-user-modal" data-modal-toggle="delete-user-modal"
        class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-red-500 bg-white  dark:bg-gray-800 rounded-lg dark:text-red-400 hover:bg-red-600 hover:text-white focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900 border border-red-400">
        <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"></path>
        </svg>
        Delete
      </button>
    </td>
  </tr>
  <UpdateUserModal v-if="isShowEditModal" :user="user" @close="closeEditModal" />
</template>
