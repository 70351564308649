<script setup lang="ts">
import { h, ref } from 'vue'
import { FieldArray, useForm } from 'vee-validate'
import { Cross1Icon } from '@radix-icons/vue'
import { Input } from '~/Components/ui/input'
import { cn } from '@/lib/utils'

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/Components/ui/form'
import { Separator } from '~/Components/ui/separator'
import { Textarea } from '~/Components/ui/textarea'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/Components/ui/select'
import { Button } from '~/Components/ui/button'

const verifiedEmails = ref(['m@example.com', 'm@google.com', 'm@support.com'])


const { handleSubmit, resetForm } = useForm({
  initialValues: {
    bio: 'I own a computer.',
    urls: [
      { value: 'https://shadcn.com' },
      { value: 'http://twitter.com/shadcn' },
    ],
  },
})

const onSubmit = handleSubmit((values) => {
  alert({
    title: 'You submitted the following values:',
    description: h('pre', { class: 'mt-2 w-[340px] rounded-md bg-slate-950 p-4' }, h('code', { class: 'text-white' }, JSON.stringify(values, null, 2))),
  })
})
</script>

<template>
  <div class="grid grid-cols-1 gap-4 px-4 pt-6 lg:px-8 xl:grid-cols-2 xl:gap-4">
    <div class="space-y-6">
      <div>
        <h3 class="text-lg font-medium">
          Profile
        </h3>
        <p class="text-sm text-muted-foreground">
          This is how others will see you on the site.
        </p>
      </div>
      <Separator />

      <form class="space-y-8" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="username">
          <FormItem>
            <FormLabel>Username</FormLabel>
            <FormControl>
              <Input type="text" placeholder="shadcn" v-bind="componentField" />
            </FormControl>
            <FormDescription>
              This is your public display name. It can be your real name or a pseudonym. You can only change this once every 30 days.
            </FormDescription>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="email">
          <FormItem>
            <FormLabel>Email</FormLabel>

            <Select v-bind="componentField">
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select an email" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectGroup>
                  <SelectItem v-for="email in verifiedEmails" :key="email" :value="email">
                    {{ email }}
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            <FormDescription>
              You can manage verified email addresses in your email settings.
            </FormDescription>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="bio">
          <FormItem>
            <FormLabel>Bio</FormLabel>
            <FormControl>
              <Textarea placeholder="Tell us a little bit about yourself" v-bind="componentField" />
            </FormControl>
            <FormDescription>
              You can <span>@mention</span> other users and organizations to link to them.
            </FormDescription>
            <FormMessage />
          </FormItem>
        </FormField>

        <div>
          <FieldArray v-slot="{ fields, push, remove }" name="urls">
            <div v-for="(field, index) in fields" :key="`urls-${field.key}`">
              <FormField v-slot="{ componentField }" :name="`urls[${index}].value`">
                <FormItem>
                  <FormLabel :class="cn(index !== 0 && 'sr-only')">
                    URLs
                  </FormLabel>
                  <FormDescription :class="cn(index !== 0 && 'sr-only')">
                    Add links to your website, blog, or social media profiles.
                  </FormDescription>
                  <div class="relative flex items-center">
                    <FormControl>
                      <Input type="url" v-bind="componentField" />
                    </FormControl>
                    <button type="button" class="absolute py-2 pe-3 end-0 text-muted-foreground" @click="remove(index)">
                      <Cross1Icon class="w-3" />
                    </button>
                  </div>
                  <FormMessage />
                </FormItem>
              </FormField>
            </div>

            <Button
              type="button"
              variant="outline"
              size="sm"
              class="w-20 mt-2 text-xs"
              @click="push({ value: '' })"
            >
              Add URL
            </Button>
          </FieldArray>
        </div>

        <div class="flex justify-start gap-2">
          <Button type="submit">
            Update profile
          </Button>

          <Button
            type="button"
            variant="outline"
            @click="resetForm"
          >
            Reset form
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>
