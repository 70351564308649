<script setup lang="ts">
import { useColorMode } from '@vueuse/core';
import Icon from '~/Components/icon/icon.vue';
import Button from '~/Components/ui/button/Button.vue';

const mode = useColorMode()
</script>

<template>
  <Button variant="outline" size="icon" @click="mode = mode === 'dark' ? 'light' : 'dark'">
    <Icon icon="radix-icons:moon"
      class="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
    <Icon icon="radix-icons:sun"
      class="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
    <span class="sr-only">Toggle theme</span>
  </Button>
</template>
