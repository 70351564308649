<script setup lang="ts">
import { Button } from '~/Components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/Components/ui/dialog'
import {
  FormControl,
  FormField,
  FormLabel,
  FormMessage
} from '~/Components/ui/form'
import { Input } from '~/Components/ui/input'
import PlusCircledIcon from '~icons/radix-icons/plus-circled'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { z } from 'zod'
import api from '~/api'
import { router } from '@inertiajs/vue3'

const schema = toTypedSchema(
  z.object({
    user: z.object({
      first_name: z.string(),
      email: z.string()
    })
  })
)

const { handleSubmit } = useForm({
  validationSchema: schema
})

const onSubmit = handleSubmit((values) => {
  router.post(api.organizationsMembers.create.path(), values, {
    onError: (err: any) => {
      console.log('err', err)
    }
  })
})
</script>

<template>
  <Dialog>
    <DialogTrigger as-child class="ml-auto mr-2">
      <Button
        variant="outline"
        size="sm"
        class="hidden h-8 ml-auto lg:flex"
      >
        <PlusCircledIcon class="w-4 h-4 mr-2" />
        {{ $t('organization.button.invite_member') }}
      </Button>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px]">
      <form @submit="onSubmit">
        <DialogHeader>
          <DialogTitle>{{ $t('organization.dialog.invite_member') }}</DialogTitle>
          <DialogDescription>
            {{ $t('organization.dialog.invite_member_description') }}
          </DialogDescription>
        </DialogHeader>
        <div class="grid gap-4 py-4">
          <FormField v-slot="{ componentField }" name="user.first_name">
            <div class="grid items-center grid-cols-4 gap-4">
              <FormLabel class="text-right">{{ $t('first_name') }}</FormLabel>
              <FormControl>
                <Input v-bind="componentField" class="col-span-3" />
              </FormControl>
              <FormMessage class="col-span-3 col-start-2" />
            </div>
          </FormField>
          <FormField v-slot="{ componentField }" name="user.email">
            <div class="grid items-center grid-cols-4 gap-4">
              <FormLabel class="text-right">{{ $t('email') }}</FormLabel>
              <FormControl>
                <Input v-bind="componentField" class="col-span-3" />
              </FormControl>
              <FormMessage class="col-span-3 col-start-2" />
            </div>
          </FormField>
        </div>
        <DialogFooter class="mt-5">
          <Button type="submit">
            {{ $t('organization.dialog.button.invite') }}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>
