<script setup lang="ts">
import type { Row } from '@tanstack/vue-table'
import { computed } from 'vue'
import { labels } from '../data/data'
import { taskSchema } from '../data/schema'
import type { Task } from '../data/schema'
import DotsHorizontalIcon     from '~icons/radix-icons/dots-horizontal'
import { Button }             from '~/Components/ui/button'
import DropdownMenu           from '~/Components/ui/dropdown-menu/DropdownMenu.vue'
import DropdownMenuContent    from '~/Components/ui/dropdown-menu/DropdownMenuContent.vue'
import DropdownMenuItem       from '~/Components/ui/dropdown-menu/DropdownMenuItem.vue'
import DropdownMenuRadioGroup from '~/Components/ui/dropdown-menu/DropdownMenuRadioGroup.vue'
import DropdownMenuRadioItem  from '~/Components/ui/dropdown-menu/DropdownMenuRadioItem.vue'
import DropdownMenuSeparator  from '~/Components/ui/dropdown-menu/DropdownMenuSeparator.vue'
import DropdownMenuShortcut   from '~/Components/ui/dropdown-menu/DropdownMenuShortcut.vue'
import DropdownMenuSub        from '~/Components/ui/dropdown-menu/DropdownMenuSub.vue'
import DropdownMenuSubContent from '~/Components/ui/dropdown-menu/DropdownMenuSubContent.vue'
import DropdownMenuSubTrigger from '~/Components/ui/dropdown-menu/DropdownMenuSubTrigger.vue'
import DropdownMenuTrigger    from '~/Components/ui/dropdown-menu/DropdownMenuTrigger.vue'

interface DataTableRowActionsProps {
  row: Row<Task>
}
const props = defineProps<DataTableRowActionsProps>()

const task = computed(() => taskSchema.parse(props.row.original))
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button
        variant="ghost"
        class="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
      >
        <DotsHorizontalIcon class="w-4 h-4" />
        <span class="sr-only">Open menu</span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" class="w-[160px]">
      <DropdownMenuItem>Edit</DropdownMenuItem>
      <DropdownMenuItem>Make a copy</DropdownMenuItem>
      <DropdownMenuItem>Favorite</DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>Labels</DropdownMenuSubTrigger>
        <DropdownMenuSubContent>
          <DropdownMenuRadioGroup :value="task.label">
            <DropdownMenuRadioItem v-for="label in labels" :key="label.value" :value="label.value">
              {{ label.label }}
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuSubContent>
      </DropdownMenuSub>
      <DropdownMenuSeparator />
      <DropdownMenuItem>
        Delete
        <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
