// JsFromRoutes CacheKey 6d0b1959f97d9a6b39b8930d66dd510f
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/users/login'),
  create: /* #__PURE__ */ definePathHelper('post', '/users/login'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/users/logout'),
}
