import type { ColumnDef } from '@tanstack/vue-table'
import { h } from 'vue'

import type { OrganizationsAccountsIndex } from '~/types/serializers'
import DataTableColumnHeader from '~/Components/v8/DataTable/DataTableColumnHeader.vue'
import DataTableRowActions from '~/Components/v8/DataTable/DataTableRowActions.vue'
import { Checkbox } from '~/Components/ui/checkbox'

export const columns: ColumnDef<OrganizationsAccountsIndex>[] = [
  {
    id: 'select',
    header: ({ table }) => h(Checkbox, {
      'checked': table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
      'onUpdate:checked': value => table.toggleAllPageRowsSelected(!!value),
      'ariaLabel': 'Select all',
      'class': 'translate-y-0.5',
    }),
    cell: ({ row }) => h(Checkbox, { 'checked': row.getIsSelected(), 'onUpdate:checked': value => row.toggleSelected(!!value), 'ariaLabel': 'Select row', 'class': 'translate-y-0.5' }),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'code',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Code' }),
    cell: ({ row }) => h('div', { class: 'w-20' }, row.getValue('code')),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Name' }),

    cell: ({ row }) => {
      return h('div', { class: 'flex space-x-2' }, [
        h('span', { class: 'max-w-[500px] truncate font-medium' }, row.getValue('name')),
      ])
    },
  },
  {
    accessorKey: 'description',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Description' }),

    cell: ({ row }) => {
      return h('div', { class: 'flex w-[100px] items-center' }, h('span', row.original.description))
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => h(DataTableRowActions, { row }),
  },
]
