// JsFromRoutes CacheKey 620f11db13d6968dd705e1463f0b1875
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  edit: /* #__PURE__ */ definePathHelper('get', '/users/profile/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/users/profile'),
  update: /* #__PURE__ */ definePathHelper('patch', '/users/profile'),
}
