import { z } from "zod";

export interface IBankAccount {
  id: number
  accountNumber: string
  bankName: string
  code: string
  name: string
  type: string
}

export const bankAccountSchema = z.object(
  {
    name: z.string(),
    code: z.string(),
    type: z.string(),
    bankName: z.string(),
    accountNumber: z.string()
  }
)

export type BankAccountSchemaType = z.infer<typeof bankAccountSchema>;

export interface IBankType {
  credit_card: string;
  other: string;
  savings: string;
  loan: string;
  current: string;
}
