<script setup lang="ts">
import { cn } from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import SidebarItem from '~/Components/Sidebar/SidebarItem.vue';
import UserIcon from '~/Icons/UserIcon.vue';
import HomeIcon from '~/Icons/HomeIcon.vue';
import api from '~/api'
const { t } = useI18n()

defineProps()
</script>

<template>
  <div :class="cn('pb-12 border-r', $attrs.class ?? '')">
    <div class="py-4 space-y-4">
      <div class="px-3 py-2">
        <div class="space-y-1">
          <SidebarItem :text="t('sidebar.dashboard')" :href="api.adminsOrganizations.index.path()">
            <template #icon>
              <HomeIcon />
            </template>
          </SidebarItem>

          <SidebarItem :text="t('sidebar.user')" :href="api.adminsUsers.index.path()">
            <template #icon>
              <UserIcon />
            </template>
          </SidebarItem>
        </div>
      </div>
    </div>
  </div>
</template>
