// JsFromRoutes CacheKey e39952222ab13aa4262a671d47915bfb
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  block: /* #__PURE__ */ definePathHelper('put', '/admins/users/:id/block'),
  unblock: /* #__PURE__ */ definePathHelper('put', '/admins/users/:id/unblock'),
  index: /* #__PURE__ */ definePathHelper('get', '/admins/users'),
  create: /* #__PURE__ */ definePathHelper('post', '/admins/users'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admins/users/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admins/users/:id'),
}
