// JsFromRoutes CacheKey dda6c0ffaf615f8c4500205bd07d8de9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  archive: /* #__PURE__ */ definePathHelper('patch', '/organization/products/:id/archive'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/products'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/products'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/products/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/products/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/products/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/products/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/products/:id'),
}
