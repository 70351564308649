// JsFromRoutes CacheKey 853f3a7e9f8d986682b7d536d414dcb7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  switch: /* #__PURE__ */ definePathHelper('patch', '/users/organizations/:id/switch'),
  index: /* #__PURE__ */ definePathHelper('get', '/users/organizations'),
  create: /* #__PURE__ */ definePathHelper('post', '/users/organizations'),
  new: /* #__PURE__ */ definePathHelper('get', '/users/organizations/new'),
}
